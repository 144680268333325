import React from "react"
import { graphql } from "gatsby"
// markup
const UserVoicePage = ({ data }) => {
  if (typeof window != "undefined") {
    window.location.href = data.strapiUservoice.url
  }

  return <div></div>
}
export default UserVoicePage

export const query = graphql`
  query ($uid: String) {
    strapiUservoice(uid: { eq: $uid }) {
      url
    }
  }
`
